import React, { useState } from "react";
import mactoresLogo from "./images/mactores-white-logo.png";

const navbarItems = [
  { label: "Data Lake", href: "#", listName: " dataLakeList" },
  { label: "EMR", href: "#", listName: " emrList" },
  { label: "GenAI", href: "#", listName: " GenAI" },
  { label: "MSK/Kinesis", href: "#", listName: " mskList" },
  // { label: 'OpenSearch', href: '#', listName: ' dataLakeList' },
  { label: "RDS", href: "#", listName: " rdsList" },
  { label: "Redshift", href: "#", listName: " redshiftList" },
  { label: "Sagemaker", href: "#", listName: " sagemakerList" },
  { label: "TimeStream", href: "#", listName: " timestreamList" },
  { label: "AWS Glue", href: "#", listName: " dataLakeList" },
  { label: "Bedrock", href: "#", listName: "bedrockList" },
  { label: "Quicksight", href: "#", listName: "quicksightList" },
  { label: "Nova", href: "#", listName: "novaList" },
];

// ...
const Navbar = ({ onItemClick, setIsIntroSelected, resetFilters }) => {
  const [activeItem, setActiveItem] = useState();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isintroSelected, setIsIntroSelectedLocal] = useState(true);

  const handleItemClick = (index) => {
    setActiveItem(index);
    setIsIntroSelectedLocal(false);
    onItemClick(navbarItems[index].label);
    setIsIntroSelected(false); // set isintroSelected to false when a navbar item is clicked
    setIsMobileMenuOpen(false); // close mobile menu when an item is clicked
  };

  const handleIntroClick = () => {
    setIsIntroSelectedLocal(true); // set isintroSelected to true when the introduction link is clicked
    setIsIntroSelected(true); // update the parent component's state
    setActiveItem(false); // clear the active item
    resetFilters();
    setIsMobileMenuOpen(false); // close the mobile menu
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    // <nav className="bg-very-dark-blue font-poppins  fixed top-0 w-full z-50 ">
    <nav className="bg-very-dark-blue font-poppins  ">
      <div className="max-w-screen-3xl m-auto p-3 sm:px-6 lg:px-8">
        <div className="flex items-center max-lg:justify-between ">
          <a href="https://mactores.com/">
            <img
              src={mactoresLogo}
              className="h-9 w-auto max-w-sm rounded-lg shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"
              alt=""
            />
          </a>
          <div className="flex items-center m-auto max-lg:m-0 overflow-y-auto whitespace-nowrap">
            <div className="hidden lg:block">
              <div className="flex items-baseline space-x-4 ">
                <div
                  className={`${
                    isintroSelected
                      ? "text-blue-500"
                      : "text-gray-300  hover:text-blue-500"
                  }  px-3 m-3  py-2 text-md font-medium `}
                  onClick={handleIntroClick}
                >
                  Introduction
                </div>
                {navbarItems.map((item, index) => (
                  <a
                    key={index}
                    href={item.href}
                    className={`${
                      activeItem === index
                        ? " text-blue-500"
                        : "text-gray-300  hover:text-blue-500"
                    } rounded-md px-3 m-3  py-2 text-md font-medium`}
                    onClick={() => handleItemClick(index)}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </div>
            <div className="lg:hidden">
              <button onClick={toggleMobileMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state. */}
      {isMobileMenuOpen && (
        <div className="lg:hidden font-poppins" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
            <div
              className={`${
                isintroSelected
                  ? "bg-gray-900 text-white"
                  : "text-gray-300 hover:bg-gray-700 hover:text-white"
              } block rounded-md px-3 py-2 text-base font-medium`}
              onClick={handleIntroClick}
            >
              Introduction
            </div>
            {navbarItems.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className={`${
                  activeItem === index
                    ? "bg-gray-900 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white"
                } block rounded-md px-3 py-2 text-base font-medium`}
                onClick={() => handleItemClick(index)}
              >
                {item.label}
              </a>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
